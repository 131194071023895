import { onValue, ref } from '@firebase/database';
import dayjs from 'dayjs';
import duration from 'dayjs/plugin/duration';
import { database } from '../libs/firebase';
import { derived, writable } from 'svelte/store';
dayjs.extend(duration);
export const useRealtimeDB = () => {
    const state = writable(null);
    const elapsedTime = derived(state, ($s) => ($s === null || $s === void 0 ? void 0 : $s.elapsedTime) || 0);
    const formatTime = (second) => {
        return dayjs.duration(second, 's').format('Y年Mヶ月D日 \n HH:mm:ss');
    };
    const elapsedTimeString = derived(elapsedTime, ($elapsedTime) => {
        return formatTime(Math.floor($elapsedTime / 1000));
    });
    const percentage = derived(elapsedTime, ($elapsedTime) => {
        const goalTime = 3 * 365 * 24 * 60 * 60 * 1000;
        return $elapsedTime / goalTime * 100;
    });
    const isPressuring = derived(state, ($s) => $s === null || $s === void 0 ? void 0 : $s.isPressuring);
    const updateState = (res) => {
        state.set(res);
    };
    const subscribeState = () => {
        const stateRef = ref(database, 'state');
        onValue(stateRef, (snapshot) => {
            const res = snapshot.val();
            updateState(res);
        });
    };
    return {
        elapsedTime,
        isPressuring,
        subscribeState,
        elapsedTimeString,
        percentage,
    };
};
